import { useStoreService } from '@goparrot-dashboard-core/store-service';
import { useUserService } from '@goparrot-dashboard-core/user-service';
import { useGetTemplateList } from '@goparrot-dashboard/campaign/src/utils/hooks';
import { arePushNotificationCampaignsEnabled } from '@goparrot-dashboard/shared-utils';
import { ReadDefaultStoreDto, ReadStoreDto } from '@goparrot/store-v2-sdk';
import { ReadVendorDto } from '@goparrot/vendor-sdk';
import React from 'react';
import { MENU_ITEMS } from '../../constants';
import { LocationItem, Menu, MerchantItem } from '../../interface';
import { isTopLevelItem } from '../../navbar/utils';
import { filterItemOnSidebar } from '../utils';
import { BackToMerchantLevel } from './BackToMerchantLevel';
import { SidebarGroup } from './SidebarGroup';
import { SidebarItem } from './SidebarItem';

type Props = {
  selectedStore?: ReadStoreDto | ReadDefaultStoreDto;
  vendor?: ReadVendorDto;
  canAccessMerchantStore: boolean;
  onBack(): void;
  onChangeNavLink(navLinkName: string): void;
};

export const ListElements = React.memo<Props>(({ selectedStore, vendor, canAccessMerchantStore, onBack, onChangeNavLink }) => {
  const {
    selectedStoreId,
    merchant: { merchantId, featuresFlags, shortTermFeatureFlags },
    isMerchant,
  } = useStoreService();
  const { roleV2 } = useUserService();
  const menuItems: (Menu<MerchantItem | LocationItem> | MerchantItem | LocationItem)[] = isMerchant ? MENU_ITEMS.MERCHANT : MENU_ITEMS.LOCATION;
  const storeId = selectedStoreId || merchantId;
  const featuresFlagList = { ...featuresFlags, ...shortTermFeatureFlags };

  const { templateList: campaignTemplates } = useGetTemplateList(merchantId, isMerchant);

  const isSidebarItemFiltered = (item: MerchantItem | LocationItem | Menu<MerchantItem | LocationItem>) =>
    roleV2 &&
    filterItemOnSidebar({
      item,
      roleV2,
      featuresFlags: featuresFlagList,
      selectedStore,
      vendorItem: vendor,
      arePushNotificationsEnabled: arePushNotificationCampaignsEnabled(campaignTemplates),
    });

  return (
    <>
      {menuItems.map((element) => {
        const isTopLevelItemFiltered = isSidebarItemFiltered(element);

        if (isTopLevelItem(element)) {
          if (isTopLevelItemFiltered) {
            const topLevelItem = element as MerchantItem | LocationItem;

            return (
              <li key={topLevelItem.label} className="tw-mb-3 tw--mt-3">
                <SidebarItem
                  item={topLevelItem}
                  storeId={storeId}
                  onClick={onChangeNavLink}
                  isMenuSettingsPageEnabled={!!shortTermFeatureFlags?.isMenuSettingsPageEnabled}
                />
              </li>
            );
          }
          return null;
        }

        const { categoryName, items } = element as Menu<MerchantItem | LocationItem>;
        const subItems = items.filter((item) => isSidebarItemFiltered(item));

        return subItems.length ? (
          <li key={categoryName} className="tw-mb-6 last:tw-mb-0">
            <SidebarGroup className="tw-pr-1.5 tw-mb-5" title={categoryName} menuItems={subItems}>
              {subItems.map((item) => (
                <div key={item.label}>
                  {!isMerchant && canAccessMerchantStore && item.label === 'sidebar.merchant.home' && <BackToMerchantLevel onClick={onBack} />}
                  <SidebarItem
                    item={item}
                    storeId={storeId}
                    onClick={onChangeNavLink}
                    isMenuSettingsPageEnabled={!!shortTermFeatureFlags?.isMenuSettingsPageEnabled}
                  />
                </div>
              ))}
            </SidebarGroup>
          </li>
        ) : null;
      })}
    </>
  );
});
