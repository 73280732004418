import { getIntlString } from '@goparrot-dashboard/i18n';
import { MarketNotifier } from '@goparrot-dashboard/shared-ui';
import { ReadWeightDto } from '@goparrot/storeitems-sdk';

export const setMetadataWeightFormErrors = (props: {
  weight: ReadWeightDto;
  setErrorField: (path: string, value: unknown) => void;
  notifyError: MarketNotifier;
}): { hasError: boolean } => {
  if (props.weight?.unit && !props.weight?.amount) {
    props.setErrorField('metadata.weight.amount', { message: getIntlString('menu.form.weight.amount', { isRootPath: true }) });
    props.notifyError({
      text: getIntlString('menu.form.submit.hasErrors', { isRootPath: true }),
    });
    return { hasError: true };
  }
  if (props.weight?.amount && !props.weight?.unit) {
    props.setErrorField('metadata.weight.unit', { message: getIntlString('menu.form.weight.unit', { isRootPath: true }) });
    props.notifyError({
      text: getIntlString('menu.form.submit.hasErrors', { isRootPath: true }),
    });
    return { hasError: true };
  }
  return { hasError: false };
};
