import * as React from 'react';
import { useCheckboxProps } from '@goparrot-dashboard/entity-form';
import { getIntlString } from '@goparrot-dashboard/i18n';
import { Toggle, Tooltip, Button, IconQuestion } from '@goparrot-dashboard/shared-ui';
import { useMenuFormContext } from '../../context';

type Props<T> = {
  field: keyof T;
  title: string;
  tooltipTitle?: string;
  checkedLabel?: string | React.ReactNode;
  uncheckedLabel?: string | React.ReactNode;
  disabled?: boolean;
  isCritical?: boolean;
  value?: boolean;
  onChange?: () => void;
};

export const ItemVerticalToggle = <T extends Record<string, boolean>>({
  field,
  tooltipTitle,
  title,
  checkedLabel,
  disabled,
  uncheckedLabel,
  isCritical,
  value,
  onChange,
}: Props<T>): React.ReactElement | null => {
  const { form } = useMenuFormContext<T>();
  const bindCheckedProps = useCheckboxProps(form);

  return (
    <div className="tw-mr-9 last:tw-mr-0">
      {tooltipTitle ? (
        <div className="tw-flex tw-items-center tw-mb-1">
          <h4 className="tw-blue-gray-900 tw-mb-0 tw-font-bold">{title}</h4>
          <Tooltip tooltipProps={{ placement: 'top' }} tooltip={<div className="tw-max-w-xs">{tooltipTitle}</div>}>
            <Button kind="clean" color="clean" className="tw-w-4 tw-h-4 tw-outline-none tw-ring-transparent tw-ml-2">
              <IconQuestion className="tw-h-5" />
            </Button>
          </Tooltip>
        </div>
      ) : (
        <h4 className="tw-blue-gray-900 tw-mb-1 tw-font-bold">{title}</h4>
      )}
      {field ? (
        <Toggle
          {...bindCheckedProps(field)}
          isCritical={isCritical}
          disabled={disabled}
          uncheckedLabel={uncheckedLabel || getIntlString('variant.no')}
          checkedLabel={checkedLabel || getIntlString('variant.yes')}
        />
      ) : (
        <Toggle
          checked={value}
          onChange={onChange}
          disabled={disabled}
          uncheckedLabel={uncheckedLabel || getIntlString('variant.no')}
          checkedLabel={checkedLabel || getIntlString('variant.yes')}
        />
      )}
    </div>
  );
};
