import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ReadTemplateDto } from '@goparrot/campaign-sdk';
import { useNotifications } from '@goparrot-dashboard/shared-ui';
import { getIntlString } from '@goparrot-dashboard/i18n';
import { campaignTemplateFacade } from '../../api/campaignTemplateApi';

export const useGetTemplateList = (
  merchantId: string,
  isEnabled = true,
): {
  templateList: ReadTemplateDto[];
  isFetchingTemplateList: boolean;
  reFetchTemplateList: () => void;
} => {
  const [templateList, setTemplateList] = useState<ReadTemplateDto[]>([]);
  const [isFetchingTemplateList, setFetchingTemplateList] = useState<boolean>(false);
  const reduxDispatch = useDispatch();
  const { notifyError } = useNotifications();

  const reFetchTemplateList = useCallback(async () => {
    if (!isEnabled) return;
    setFetchingTemplateList(true);
    if (!merchantId) return;
    try {
      const result: ReadTemplateDto[] = await campaignTemplateFacade.getList(merchantId);
      setTemplateList(result);
    } catch (error) {
      notifyError({ text: getIntlString('notification.campaign.get.template.error.description') });
    } finally {
      setFetchingTemplateList(false);
    }
  }, [merchantId, reduxDispatch, isEnabled]);
  useEffect(() => {
    if (!templateList.length) {
      reFetchTemplateList();
    }
  }, [reFetchTemplateList, merchantId, templateList]);

  return { templateList, isFetchingTemplateList, reFetchTemplateList };
};
